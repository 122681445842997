import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';
import PreWorkItems from '../components/PreWorkItems';

export default function PreWorkListPage({ data, pageContext }) {
  const preworks = data.preworks.nodes;
  const breadcrumbs = [
    {
      title: 'Pre-Work',
      url: '/pre-work',
    },
  ];
  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <PreWorkItems preworks={preworks} />
    </Layout>
  );
}

export const query = graphql`
  query PreWorkListQuery($url: String) {
    preworks: allSanityPreWork(
      filter: { family: { url: { current: { eq: $url } } } }
    ) {
      nodes {
        name
        id
        url {
          current
        }
        image {
          asset {
            fluid(maxWidth: 300) {
              ...GatsbySanityImageFluid
            }
          }
        }
        family {
          name
          url {
            current
          }
          id
        }
      }
    }
  }
`;
